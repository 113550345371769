
$(document).ready(function() {
  
	$('.accordion_content .accordion_head').on('click', f_acc);
});
 
function f_acc(){

  $('.accordion_content .accordion_body').not($(this).next()).slideUp(1000);

    $(this).next().slideToggle(500);
}