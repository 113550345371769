
$(document).ready(function() {

    $('.menu').hover(function() {
        $(this).toggleClass('menu_active');
    });
    
    $('.menu').on('click', function() {
        $('.slider_menu').addClass('slider_menu_active');
    });
    
    $('.slider_menu > span').on('click', function() {
        $('.slider_menu').removeClass('slider_menu_active');
    });

});

