

  $(document).ready(function(){

    if( $('.slider').length ){

        $('.slider').slick({
            dots: true,
            infinite: true,
            speed: 300,
            autoplay: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: '<button class="icon-left prev"></button>',
            nextArrow: '<button class="icon-right next"></button>',
        });

    }
  });